<template>
  <div v-if="billing_account">
    <QuotationDescriptorCard
      :fixed_params="
        _.merge(
          $d.relationFixedParams('Quotation', 'BillTo', [billing_account]),
        )
      "
      :init_params="
        _.merge(
          $d.relationFixedParams('Quotation', 'SendTo', _.get(billing_account, $d.getRelationAliasByNames('BillingAccount', 'HasMainInvoiceContactPersons')))
        )
      "
      :_index_params="$d.relationIndexParams('Quotation', 'BillTo', billing_account.id)"
      :stateIdentifier="'_billing_account_' + billing_account.id"
      class_name="Quotation"
      title="Quotations"
    ></QuotationDescriptorCard>
  </div>
  <div v-else>
    <div class="alert alert-warning">Billing Account Required for Quotation</div>
  </div>
</template>

<script>
export default {
  components: {
    DescriptorCard: () => import('@/descriptor/coreui/DescriptorCard'),
    QuotationDescriptorCard: () => import('../../Quotation/QuotationDescriptorCard'),
  },
  props: {
    billing_account: {
      required: true,
    },
  },
  data: () => {
    return {};
  },
  computed: {},
  methods: {},
  created() {
    this._ = _;
  },
};
</script>
